<template>
  <div class="finance-overview">
    <el-card v-if="detail.account_period === 1" class="x-card-title mb-3" shadow="hover">
      <template v-if="detail.account_period === 1">
        <div class="df">
          <div class="balance-left">
            帐户总余额<br /> <span style="color:#e21d00">{{ detail.cash_account || 0.0 | unitPrice('￥') }}</span>
          </div>
          <div>
            <div class="balance-right" style="margin-bottom:20px;">
              锁定金额<br /> <span style="color:#e21d00">{{ detail.lock_account || 0.0 | unitPrice('￥') }}</span>
            </div>
            <div class="balance-right">
              可提现金额<br /> <span style="color:#00dd9b">{{ detail.un_account || 0.0 | unitPrice('￥') }}</span>
              <el-button @click="txShow = true" size="small" type="primary" style="margin-left:60px;">
                提现
              </el-button>
            </div>
          </div>
        </div>
        <!-- <div>可提现余额</div>
        <p>
          <span class="cs" style="color: rgb(93, 190, 45)">
            {{ detail.un_account || 0.0 | unitPrice('￥') }}
          </span>
        </p>
        <div>
          <el-button @click="txShow = true" size="small" type="primary">
            提现
          </el-button>
        </div> -->
      </template>
    </el-card>
    <el-card v-if="detail.account_period === 0" class="x-card-title mb-3" shadow="hover" header="预付款账户">
      <template v-if="!isUncleCake">
        <p>
          <span v-if="(info.money || info.money === 0) && detail.third_shop !== 'jingdong'" class="cs"
            style="color: rgb(93, 190, 45)">
            {{ detail.balance_account || 0.0 | unitPrice('￥') }} +
            {{ info.money | unitPrice('￥') }}
          </span>
          <span v-else class="cs" style="color: rgb(93, 190, 45)">
            {{ detail.balance_account || 0.0 | unitPrice('￥') }}
          </span>
        </p>

        <div>
          <el-button @click="handleImgLook" :disabled="(!(info.money || info.money === 0))" size="small" type="primary">
            充值截图查看
          </el-button>
          <el-button @click="handleQueren" :disabled="(!(info.id || info.id === 0))" size="small" type="primary">
            确认到账
          </el-button>
        </div>
      </template>
      <template v-if="isUncleCake">
        <span class="cs" style="color: rgb(93, 190, 45)">
          {{ unclecakeMoney || 0.0 | unitPrice('￥') }}
        </span>
      </template>
    </el-card>

    <en-table-layout v-if="detail.account_period === 1" :tableData="pageData.data" :redundancy-height="190">
      <template slot="toolbar">
        <div class="toolbar-title" style="align-self: baseline;">
          {{ detail.account_period === 1 ? '提现记录' : '充值记录' }}
        </div>

        <el-form-item class="col-auto">
          <el-date-picker :default-time="['00:00:00', '23:59:59']" value-format="timestamp" style="width: 230px;"
            v-model="daterangeDate" type="daterange" size="medium" @change="daterangeDateChange" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期" />
        </el-form-item>

        <div class="col-auto" style="align-self: baseline;">
          <el-button @click="getBalanceList('getBalanceList')" size="small" type="primary">
            搜索
          </el-button>
        </div>
      </template>
      <template slot="table-columns">
        <el-table-column label="流水号" prop="sn"></el-table-column>
        <el-table-column label="提现账号" prop="account_num"></el-table-column>
        <el-table-column label="提现金额">
          <template slot-scope="scope">{{
            scope.row.cash_money | unitPrice('￥')
          }}
          </template>
        </el-table-column>
        <el-table-column label="提现手续费">
          <template slot-scope="scope">{{
            scope.row.fee_money | unitPrice('￥')
          }}
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span>{{ scope.row.pass | passFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="申请提现时间" width="160">
          <template slot-scope="scope">{{
            scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm')
          }}
          </template>
        </el-table-column>
        <el-table-column label="操作完成时间" width="160">
          <template slot-scope="scope">{{
            scope.row.complete_time | unixToDate('yyyy-MM-dd hh:mm')
          }}
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total"></el-pagination>
    </en-table-layout>

    <en-table-layout v-if="detail.account_period === 0 && !isUncleCake" :tableData="pageData.data"
      :redundancy-height="190">
      <template slot="toolbar">
        <div class="toolbar-title">
          {{ detail.account_period === 1 ? '提现记录' : '充值记录' }}
        </div>
        <el-form-item class="col-auto">
          <el-date-picker :default-time="['00:00:00', '23:59:59']" value-format="timestamp" style="width: 230px;"
            v-model="daterangeDate" type="daterange" size="medium" @change="daterangeDateChange" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>

        <el-button @click="getBalanceList('GET_shopBalanceLog')" size="small" type="primary"
          style="align-self: baseline;">
          搜索
        </el-button>
      </template>
      <template slot="table-columns">
        <el-table-column label="编号" prop="id"></el-table-column>
        <el-table-column label="供应商名称">
          <template slot-scope="scope">{{ scope.row.shop_name }}</template>
        </el-table-column>
        <el-table-column label="金额">
          <template slot-scope="scope">{{
            scope.row.money | unitPrice('￥')
          }}
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span>{{ scope.row.status | passFilter1 }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="申请提现时间" width="160">
          <template
            slot-scope="scope"
          >{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column> -->
        <el-table-column label="操作完成时间" width="160">
          <template slot-scope="scope">{{
            scope.row.audit_time || '' | unixToDate('yyyy-MM-dd hh:mm:ss')
          }}
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total"></el-pagination>
    </en-table-layout>

    <el-dialog :visible.sync="centerDialogVisible" modal close-on-click-modal custom-class="dialog">
      <el-carousel :autoplay="false" arrow="never">
        <el-carousel-item v-for="item in [info.pay_img]" :key="item">
          <img :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>

    <!--提现-->
    <el-dialog title="提现" :visible.sync="txShow" width="600px">
      <div class="addMealForm">
        <el-form :model="txInfo" ref="txInfo" :rules="txRules" label-width="135px">
          <el-form-item class="w200" label="提现金额" prop="cash_money">
            <el-input-number style="width: 200px" :controls="false" v-model="txInfo.cash_money" :min="0">
              <template slot="append">元</template>
            </el-input-number>
          </el-form-item>
          <el-form-item label="请选择提现方式" prop="out_way">
            <el-radio-group v-model="txInfo.out_way" @change="changeOutWay">
              <el-radio :label="3">银行卡</el-radio>
              <el-radio :label="2">支付宝</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="提现账号" prop="account_id">
            <el-select style="width: 200px" v-model="txInfo.account_id" placeholder="请选择提现账户">
              <el-option v-for="item in list1" :key="item.id" :label="item.account_num" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="txShow = false" size="small">取消
        </el-button>
        <el-button type="primary" @click="handleIsTixian('txInfo')" size="small">确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as API_account from '@/api/account';
// GET_shopBalanceLog
import { handleDownload } from '@/utils';
import { Foundation } from '@/../ui-utils';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'goodsList',
  components: { EnTableLayout },
  data () {
    return {
      unclecakeMoney: '',//蛋糕金额
      txShow: false,
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      txInfo: {
        out_way: 3,
        account_id: ''
      },
      txRules: {
        account_id: [
          {
            required: true,
            message: '请选择提现账号',
            trigger: 'change'
          },
        ],
        cash_money: [
          {
            required: true,
            message: '请输入提现金额',
            trigger: 'blur'
          },
        ],
      },
      list1: [],

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        shop_name: '',
        seller_name: '',
        start_time: '',
        end_time: '',
        logi_id: '',
        pay_type: '',
        ca_type: 0,
      },

      daterangeDate: [],

      /** 列表分页数据 */
      pageData: { data: [] },

      /** 快递列表 */
      logiList: [],

      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      detail: {},
      info: {},
      centerDialogVisible: false, // 显示查看器
    };
  },
  filters: {
    passFilter (val) {
      switch (val) {
        case 0:
          return '待审核';
        case 1:
          return '审核通过';
        case 2:
          return '审核不通过';
      }
    },
    passFilter1 (val) {
      // 审核状态 打款中、已到账、打款失败,
      switch (val) {
        case 0:
          return '打款中';
        case 1:
          return '已到账';
        case -1:
          return '打款失败';
      }
    },
  },
  // activated() {
  //   this.getBalanceList();
  // },
  created () {
    this.getAcountMoney();
  },
  mounted () {
    //搜索余额，用于企业/供应商搜索代收现金账户总额
    this.getAcountMoney();
  },
  methods: {
    getShopBalanceLog () {
      API_account.getSupplierShopBalanceLog().then((res) => {
        // console.log('getShopBalanceLog:', res,);
        this.info = res;
      });
    },
    handleImgLook () {
      // this.showViewer = true;
      this.centerDialogVisible = true;
    },
    handleQueren () {
      this.$confirm('确认到账, 是否继续?', '提示', {
        type: 'warning',
      })
        .then(() => {
          let _id = this.info.id;
          API_account.auditShopBalance(_id, { status: 1 }).then(() => {
            this.getBalanceList();
            this.$message.success('操作成功！');
            this.getShopBalanceLog();
            this.getAcountMoney();
          });
        })
        .catch((_) => {
        });
    },
    getXlist () {
      let obj = {
        out_type: this.txInfo.out_way === 3 ? 1 : 2,   // 1 银行卡  2 支付宝
      };
      API_account.getXlist(obj).then((response) => {
        // console.log(response, '获得的结算账号列表');
        this.list1 = response;
      });
    },
    handleIsTixian (formName) {
      let pa = {
        account_type: 2,
        ...this.txInfo,
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          API_account.tranLateMoney(pa).then((response) => {
            this.txShow = false;
            this.$message.success('提交成功');
            this.$refs['txInfo'].resetFields();
            this.getAcountMoney();
          });
        } else {
          return false;
        }
      });
    },
    getAcountMoney () {
      if (this.isUncleCake) {
        API_account.getCookieSupperAccountMoney().then((response) => {
          this.unclecakeMoney = response
        });
      }
      API_account.getShopBalance().then((response) => {
        // console.log(response, 'response');
        this.detail = response;

        this.getShopBalanceLog();
        //搜索列表
        this.getBalanceList();
        //获取提现账户列表
        this.getXlist();
      });
    },
    changeOutWay () {
      //获取提现账户列表
      this.getXlist();
      this.$data.txInfo.account_id = '';
    },
    getBalanceList (fix1885 = false) {
      this.loading = true;
      let params = {   // 这块代码导致了bug1885（禅道bug编号）的出现，由于不知道当时的具体需求不便直接删除
        account_type: 2,
        page_no: 1,
        page_size: 20,
        start_time: this.params.start_time,
        end_time: this.params.end_time,
      };
      if (fix1885) {
        params.page_no = this.params.page_no
        params.page_size = this.params.page_size
      }
      let Name = '';
      // 0 充值记录  1提现记录
      // console.log(this.detail.account_period, 'this.detail.account_period');
      if (this.detail.account_period === 1) {
        Name = 'getBalanceList';
      } else {
        Name = 'GET_shopBalanceLog';
      }
      API_account[Name](params).then((response) => {
        this.loading = false;
        this.pageData = response;
      });
    },

    daterangeDateChange (val) {
      this.params.start_time = val ? val[0] / 1000 : '';
      this.params.end_time = val ? val[1] / 1000 : '';
    },

    submitImport () {
      this.importLoading = true;
      API_account.getSettleOrderList({}).then((response) => {
        let nameLabel =
          this.shopInfo.shop_type !== 2 ? '供应商名称' : '店铺名称';
        let nameValue =
          this.shopInfo.shop_type !== 2 ? 'seller_name' : 'shop_name';
        let priceValue =
          this.shopInfo.shop_type === 2 ? 'order_price' : 'shop_order_price';
        let pointValue =
          this.shopInfo.shop_type === 2 ? 'consume_point' : 'shop_consume_point';
        response.forEach((item) => {
          item.complete_time = Foundation.unixToDate(
            item.complete_time,
            'yyyy-MM-dd hh:mm'
          );
          if (item[pointValue]) {
            item[priceValue] =
              item[priceValue] + ' + ' + item[pointValue] + '积分';
          }
        });
        let tHeaders = [
          nameLabel,
          '订单号',
          '买家会员号',
          '订单金额',
          '快递单号',
          '快递名称',
          '订单日期',
        ];
        let filterVals = [
          nameValue,
          'sn',
          'member_name',
          priceValue,
          'ship_no',
          'logi_name',
          'complete_time',
        ];
        handleDownload(response, tHeaders, filterVals, '结算订单列表');
        this.importLoading = false;
      });
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.getBalanceList(true);
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.getBalanceList(true);
    },

    /** 搜索事件触发 */
    searchEvent (data) {
      this.params = {
        ...this.params,
        keyword: data,
      };
      this.getBalanceList();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  img {
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: contain;
  }
}

.balance-left {
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  padding: 30px 60px 0 0;
  margin-right: 60px;
  border-right: 1px solid #979797;
}

.balance-right {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

.goods-image {
  width: 50px;
  height: 50px;
}

.ye {
  color: #cc3300;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 15px;
}

.cs {
  font-size: 36px;
  font-weight: 600;
  line-height: 36px;
  padding: 20px 0px;
}
</style>
